import React from "react"
import {
  Heading,
  ImgCont,
  PointIcon,
  Points,
  PointText,
  PointUl,
  TextCont,
  WhyContainer,
  Wrapper,
} from "../provider-section/styles/whyNow"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { StaticImage } from "gatsby-plugin-image"
const data = [
  {
    id: 1,
    icon: <IoIosCheckmarkCircle />,
    point: "Healthcare cost is on the rise ",
  },
  {
    id: 2,
    icon: <IoIosCheckmarkCircle />,
    point:
      "Prevention is always better than cure; prevent disease complications ",
  },
  {
    id: 3,
    icon: <IoIosCheckmarkCircle />,
    point: "Technology has made healthcare accessible and affordable",
  },
  {
    id: 4,
    icon: <IoIosCheckmarkCircle />,
    point: "Better patient outcomes at a lower cost",
  },
]
export default function whyUs() {
  return (
    <WhyContainer>
      <Wrapper>
        <ImgCont>
          <StaticImage height={600} src="../../images/business-profit.png" alt="Business profit" placeholder="blurred" />
        </ImgCont>
        <TextCont>
          <Heading>Why Now</Heading>
          <PointUl>
            {data.map(item => {
              const { id, icon, point } = item
              return (
                <Points key={id}>
                  <PointIcon>{icon}</PointIcon>
                  <PointText>{point}</PointText>
                </Points>
              )
            })}
          </PointUl>
        </TextCont>
      </Wrapper>
    </WhyContainer>
  )
}
